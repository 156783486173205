<template>
    <v-card class="rounded-0 mb-3" outlined>
        <!-- Cabeçalho -->
        <v-card-title
            class="pa-0 ma-0 text-h6 grey lighten-4 d-flex"
            @click="toggleAberto"
        >
            <v-icon class="text-h4 gray--text">{{ iconeExpandido }}</v-icon>
            <div class="flex-grow-1">
                {{ limite(artigo.assunto) }}
            </div>
            <div
                class="text-caption mr-2 d-flex flex-col flex-grow-0 align-end"
            >
                <v-icon
                    color="yellow"
                    dark
                    class="mr-2"
                    v-if="!artigo.visualizado"
                    >mdi-star</v-icon
                >
                <div class="mr-2" v-if="anexos.length > 0">
                    {{ anexos.length
                    }}<v-icon small dense class="">mdi-paperclip</v-icon>
                </div>
                <div>{{ formataData(artigo.criado) }}</div>
            </div>
            <v-icon class="text-h5 gray--text mx-2">{{ iconeDirecao }}</v-icon>
        </v-card-title>
        <!-- Corpo -->
        <v-card-text v-if="aberto" class="pa-6">
            <div v-html="artigoHtml"></div>
        </v-card-text>
        <v-card-actions
            class="pa-6 grey lighten-4 d-flex flex-column align-start"
            v-if="mostrarAnexos"
        >
            <div v-for="anexo in anexos" :key="anexo.filename">
                <a
                    :download="anexo.filename"
                    :type="anexo.contenttype"
                    :href="createContent(anexo)"
                >
                    {{ anexo.filename }} ({{ anexo.filesize }})<v-icon
                        class="text-h4 gray--text mx-0"
                        >mdi-download-box</v-icon
                    >
                </a>
            </div>
        </v-card-actions>
    </v-card>
</template>

<script>
import utils from "@/utils/general"; // formataData, limite, etc....
import { mapActions } from "vuex";

const mapaDirecao = {
    entrada: "mdi-message-arrow-left-outline",
    saida: "mdi-message-arrow-right-outline",
};

export default {
    name: "detalhe-artigo",
    props: {
        artigo: {
            type: Object,
            required: true,
        },
        ticketId: {
            type: Number,
            required: true,
        },
        aberto: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            artigoHtml: "",
            anexos: [],
        };
    },
    computed: {
        iconeExpandido: function () {
            return this.aberto ? "mdi-menu-down" : "mdi-menu-right";
        },
        iconeDirecao: function () {
            return mapaDirecao[this.artigo.tipo];
        },
        mostrarAnexos() {
            return this.aberto && this.anexos.length > 0;
        },
    },
    methods: {
        ...mapActions("visualizacoes", ["visualizarArtigo"]),
        createContent(anexo) {
            let data = "data:" + anexo.contenttype + ";base64," + anexo.content;
            return data;
        },
        toggleAberto() {
            if (!this.aberto && !this.artigo.visualizado) {
                this.visualizarArtigo({
                    ticketId: this.ticketId,
                    articleId: this.artigo.id,
                });
                this.$emit("artigoVisualizado", this.artigo.id);
            }
            this.$emit("abrirFechar", {
                articleId: this.artigo.id,
                aberto: !this.aberto,
            });
        },
        ...utils,
    },
    mounted() {
        this.artigoHtml = Buffer.from(
            this.artigo.anexos.filter(
                (anexo) => anexo.disposition === "inline"
            )[0].content,
            "base64"
        ).toString("utf8");
        this.anexos = this.artigo.anexos.filter(
            (anexo) => anexo.disposition !== "inline"
        );
    },
};
</script>
